/**
 * Manage app cookies using vue-cookie
 */

import Vue from 'vue'
import VueCookie from 'vue-cookie'

Vue.use(VueCookie)
window.$cookie = Vue.prototype.$cookie

export default window.$cookie
