<template>
  <v-row class="full" @keyup.enter="submit" style="overflow-y:auto; overflow-x: hidden">
    <v-col cols="12" md="3" lg="5" height="100%" style="height: auto" class="blue elevation-9 d-flex align-center justify-center">
      <div class="d-flex flex-column justify-space-between align-center" style="width: 100%">
      <v-img src="../assets/Account-amico.png" alt="MP Administration" width="450"></v-img>
      </div>
    </v-col>
    <v-col class="py-16 px-16 py-lg-16 px-lg-10 ml-lg-16 align-content-center">
      <v-row>
        <v-col cols="12" md="6" class="mt-md-10 mb-md-16">
          <v-img src="../assets/logo-full.svg" alt="MP Administration" width="100%" contain max-width="370px"></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" lg="6" class="mt-8 mt-md-10 mt-lg-16">
          <h2>{{ $vuetify.lang.t('$vuetify.auth.login.title') }}</h2>
          <p>{{ $vuetify.lang.t('$vuetify.auth.login.subtitle') }}
          </p>
          <v-form ref="form" v-model="form.valid" lazy-validation>
            <v-row>
              <v-col>
                <v-alert
                  v-model="ui.alert.enable"
                  :dismissible="ui.alert.dismissible"
                  :icon="ui.alert.icon"
                  :type="ui.alert.type"
                  class="mb-0 mt-3 mb-3"
                >
                  {{ ui.alert.message }}
                </v-alert>
                <v-text-field
                  v-model="form.data.email"
                  :rules="form.rules.email"
                  :label="$vuetify.lang.t('$vuetify.auth.login.form.email.label')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="form.data.password"
                  :append-icon="ui.show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="form.rules.password"
                  :type="ui.show_password ? 'text' : 'password'"
                  name="password"
                  :label="$vuetify.lang.t('$vuetify.auth.login.form.password.label')"
                  required
                  @click:append="ui.show_password = !ui.show_password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  class="mt-0"
                  v-model="form.data.remember"
                  :label="$vuetify.lang.t('$vuetify.auth.login.form.remember.label')"
                ></v-checkbox>
              </v-col>
              <v-col>
                <p class="mt-2 text-right">
                  <a href="/password-forgot">{{ $vuetify.lang.t('$vuetify.auth.login.forgotten.title') }}</a>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-8">
                <v-btn
                  class="white--text"
                  elevation="2"
                  large
                  rounded
                  color="blue"
                  @click="submit"
                > {{ $vuetify.lang.t('$vuetify.auth.login.submit.title') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import _ from 'lodash'
import Sessions from '../plugins/sessions'
import Cookie from '../plugins/cookies'

export default {
  data: () => ({
    test: '',
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      },
      show_password: false
    },
    form: {
      valid: false,
      data: {
        email: '',
        password: '',
        remember: false
      },
      rules: {}
    }
  }),
  watch: {
    'data.username': function () {
      this.remember()
    },
    'data.remember': function () {
      this.remember()
    }
  },
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
    validate () {
      return this.$refs.form.validate()
    },
    remember: function (n) {
      if (this.form.data.remember) Cookie.set('login_username', this.form.data.email, { expires: '2M' })
      else Cookie.delete('login_username')
    },
    async submit () {
      this.remember(this.form.data.remember)
      if (!this.validate()) {
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/login', {
          email: this.form.data.email,
          password: this.form.data.password
        }).then((response) => {
          Sessions.set('auth_credentials', response.data)
          this.$router.push({
            name: 'Home'
          })
        })
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.auth.login.notification.invalid'))
              break
            case 404:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.auth.login.notification.not_found'))
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.auth.login.notification.unknown'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  created () {
    this.form.rules = {
      email: [
        value => {
          if (_.size(value) <= 0) {
            return true
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (pattern.test(value)) || this.$vuetify.lang.t('$vuetify.general.clients.add.ui.form.fields.email.rules.invalid')
        },
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ],
      password: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required')
      ]
    }
  },
  mounted () {
    this.form.data.email = Cookie.get('login_username')
    if (this.form.data.email != null && this.form.data.email.length >= 1) this.form.data.remember = true
  }
}
</script>

<style lang="scss">
.full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.col {
  position: relative;
}
</style>
